<template>
  <div class="bgc_f5f5f5">
    <Topbar @search="search" :listnone="listnone"></Topbar>
    <SuS></SuS>
    <div class="pc-title">首页>详情页</div>
    <!-- 组件位置 -->
    <TypeList :islist="islist" @list1="list1"></TypeList>
    <!-- 筛选 -->
    <div class="screen">
      <div
        :class="[item.ischeck ? 'screen-left2' : 'screen-left']"
        v-for="(item, index) in screenlist"
        :key="index"
        @click="change(index, screenlist)"
      >
        {{ item.name }}
      </div>
      <div class="screen-right">
        <div class="price2">
          <input
            class="money"
            placeholder="￥"
            v-model="minPrice"
            value="minPrice"
          />
          <div class="line"></div>
          <input
            class="money"
            placeholder="￥"
            v-model="maxPrice"
            value="maxPrice"
          />
          <div class="queding" @click.stop="shaixianmoney()">确定</div>
        </div>
        <div class="nums">共{{ total }}件商品</div>
      </div>
    </div>

    <div class="center">
      <div
        class="center-item"
        v-for="(item, index) in allshoplist"
        :key="index"
        @click="togoodsdetail(item.Id)"
      >
        <img :src="item.CoverImg" alt="" />
        <div class="center-item-font">
          {{ item.GoodsName }}
        </div>
        <div class="center-price">
          <div class="center-price-money">￥</div>
          <div class="center-price-price">{{ item.DefaultPrice }}</div>
          <div class="center-price-people">{{ item.BuyCount }}人付款</div>
        </div>
        <!-- <div class="pre">
          <div class="pre-one">劵</div>
          <div class="pre-two">
            满{{ item.FullMoeny }}减{{ item.CouponMoeny }}
          </div>
        </div> -->
      </div>
      <el-pagination
        v-if="allshoplist.length > 0"
        background
        :pager-count="7"
        :page-size="pageSize"
        :current-page.sync="pageIndex"
        layout="prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total"
      >
      </el-pagination>
    </div>
    <div v-if="allshoplist.length <= 0" style="margin-bottom: 50px">
      <nocontent name="暂无数据"></nocontent>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>
import Topbar from '../../PCshop/components/topbar.vue'
import Foter from '../../PCshop/components/shopfooter.vue'
import SuS from '../../PCshop/components/suspen.vue' //悬浮
import TypeList from '../../PCshop/components/typelist.vue'
import nocontent from "@/views/shop/components/nocontent";
import { qeryShopGoodsList, queryGoodsCategoryTree } from "@/api/shop";
export default {
  components: {
    Topbar, Foter, SuS, TypeList, nocontent
  },
  data () {
    return {
      shaixuanindex: 0,
      islist: true,
      categoryList: [],
      listnone: true,
      keyWords: '',
      total: 0, //商品总数
      ism: false,//弹出价格选择
      minPrice: "",//最低价
      maxPrice: "",//最高价
      minPoints: "",
      maxPoints: "",
      sortType: "asc",
      sortField: "Sort",
      categoryId: 0,
      allshoplist: [],//所有商品列表
      pageIndex: 1,
      pageSize: 15,
      type: [
        {
          name: '分类',
        },
        {
          name: '品牌',
        },
        {
          name: '艺术风格',
        },
        {
          name: '试用场景',
        },
        {
          name: '艺术种类'
        }
      ],
      screenlist: [
        {
          name: '综合',
          ischeck: false
        },
        {
          name: '销量',
          ischeck: false
        },
        {
          name: '价格',
          ischeck: false
        },
      ],
      typename: [
        {

        }
      ],
      isselect: false,
    }
  },
  created () {
    console.log(this.$route, '')
    this.value = this.$route.query.key
    // this.goodsId = this.$route.query.id;
    this.categoryId = this.$route.params.categoryId
    this.postQeryShopGoodsList();
    this.postQeryShopGoodsCategoryList()
  },
  mounted () {
  },
  methods: {
    //分类列表数据
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response.slice(0, 6);
      console.log(this.categoryList, 87879879)
    },
    sortClick (sortField) {
      if (this.sortField != sortField) {
        this.sortType = "asc";
      } else {
        if (this.sortType == "asc") {
          this.sortType = "desc";
        } else if (this.sortType == "desc") {
          this.sortType = "asc";
        }
      }
      this.sortField = sortField;
      if (this.sortField == "Sort") {
        this.sortType = 'asc';
      }

      this.pageIndex = 1;
      this.allshoplist = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    change (idx, arr) {
      // this.isselect = !this.isselect
      // arr = arr.map((item, index) => {
      //   if (idx == index) {
      //     item.ischeck = this.isselect
      //   }
      //   return item
      // })
      arr.forEach((item, index) => {
        if (idx == index) {
          item.ischeck = item.ischeck == true ? false : true
        } else {
          item.ischeck = false
        }
      })
      if (idx == 1) {
        this.sortClick('BuyCount')
      } else if (idx == 0) {
        this.sortClick('Sort')
      } else if (idx == 2) {
        this.sortClick('DefaultPrice')
      }
    },
    ismoney () {
      this.ism = true;
    },
    //金额确定
    shaixianmoney () {
      this.ism = false
      this.postQeryShopGoodsList()
    },
    //   跳转到商品详情页
    togoodsdetail (id) {
      console.log(id)
      this.$router.push({ path: '/PCshop/goods/goodsdetail', query: { id: id } });
      //   {path: '/backend/order', query: {selected: "2"}}
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postQeryShopGoodsList();
    },
    // topbar传递的搜索数据
    search (data) {
      // console.log(data.length, 9999)
      this.total = data.length
      this.allshoplist = data
    },
    //typelist传递的数据
    list1 (data) {
      // console.log(data, 777)
      this.allshoplist = data
    },
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsShelf = 1;
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.CategoryIds = this.categoryId ? [this.categoryId + ''] : []
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      const res = await qeryShopGoodsList(parm);
      // console.log(res, 888888888)
      this.total = res.dataCount //总数
      this.goodsRecommendList = res.data;
      this.allshoplist = this.goodsRecommendList
      // console.log(this.allshoplist, 8888)
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
.typebox {
  width: 1100px;
  margin: 0 auto;
}
.typebox-row {
  display: flex;
  .type-title {
    width: 110px;
    height: 36px;
    background: #efefef;
    font-size: 12px;
    color: #999999;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e7e7;
  }
  .type-name {
    padding-left: 10px;
    flex: 1;
    background: #ffffff;
    height: 36px;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    .type-name-font {
      font-size: 12px;
      color: #333333;
      margin-top: 10px;
    }
  }
}
.screen {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  background: #ffffff;
  margin-top: 11px;
  border: 1px solid #eaeaea;
  .screen-left {
    cursor: pointer;
    width: 90px;
    height: 40px;
    border-right: 1px solid #eaeaea;
    text-align: center;
    line-height: 38px;
    font-size: 12px;
    color: #333333;
    background-image: url("../../../assets/images/typeicon2.png");
    background-repeat: no-repeat;
    background-position: 80% 52%;
  }
  .screen-left2 {
    cursor: pointer;
    width: 90px;
    height: 40px;
    text-align: center;
    line-height: 38px;
    font-size: 12px;
    color: #e31436;
    border: 1px solid #e1251b;
    background-image: url("../../../assets/images/typeicon.png");
    background-repeat: no-repeat;
    background-position: 80% 52%;
  }
  .screen-right {
    display: flex;
    flex: 1;
    justify-content: space-between;
    .price {
      position: relative;
      display: flex;
      margin-top: 5px;
      margin-left: 20px;
      .money {
        width: 80px;
        height: 29px;
        border: 1px solid #dddddd;
        font-size: 12px;
        color: #999999;
        padding-top: 4px;
        padding-left: 5px;
      }
      .line {
        width: 20px;
        height: 2px;
        margin: 14px 9px 0 9px;
        background: #999999;
      }
    }
    .price2 {
      cursor: pointer;
      border: 1px solid #efefef;
      background: #ffffff;
      display: flex;
      margin-top: 4px;
      margin-left: 20px;
      .money {
        width: 70px;
        height: 30px;
        border: 1px solid #dddddd;
        font-size: 12px;
        color: #999999;
        padding-top: 4px;
        padding-left: 5px;
      }
      .line {
        width: 20px;
        height: 2px;
        margin: 14px 9px 0 9px;
        background: #999999;
      }
      .queding {
        width: 70px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: rgb(242, 66, 52);
        color: #ffffff;
        margin-left: 20px;
      }
    }
    .nums {
      margin-right: 20px;
      margin-left: auto;
      margin-top: 7px;
    }
    .jiantou {
      img {
        margin-top: 4px;
        width: 23px;
        height: 28px;
      }
      display: flex;
    }
  }
}
.buts {
  width: 74px;
  height: 214px;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  position: fixed;
  top: 25.4%;
  left: 75%;
}
.center {
  width: 1137px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-left: 30px;
  .center-item {
    margin-right: 15px;
    margin-top: 20px;
    width: 204px;
    background: #ffffff;
    padding: 28px 27px 20px 27px;
    box-sizing: border-box;
    img {
      width: 150px;
      height: 150px;
    }
    .center-item-font {
      margin-top: 30px;
      width: 168px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .center-price {
      display: flex;
      margin-top: 14px;
      // justify-content: space-between;
      .center-price-money {
        color: #f40707;
        font-size: 12px;
        margin-top: 5px;
      }
      .center-price-price {
        color: #f40707;
        font-size: 20px;
        font-weight: bold;
      }
      .center-price-people {
        color: #999999;
        margin-top: 5px;
        margin-left: 8px;
      }
    }

    .pre {
      display: flex;
      .pre-one {
        width: 30px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        line-height: 26px;
      }
      .pre-two {
        width: 78px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        margin-left: 10px;
        line-height: 26px;
      }
    }
  }
}
/deep/.el-pagination {
  width: 100%;
}
.typebox {
  width: 1100px;
  margin: 0 auto;
}
.typebox-row {
  display: flex;
  .type-title {
    width: 110px;
    height: 36px;
    background: #efefef;
    font-size: 12px;
    color: #999999;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e7e7;
  }
  .type-name {
    padding-left: 10px;
    flex: 1;
    background: #ffffff;
    height: 36px;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    .type-name-font {
      display: flex;
      margin-left: 30px;
      font-size: 12px;

      margin-top: 10px;
      .text2 {
        cursor: pointer;
        color: #333333;
      }
      .text3 {
        cursor: pointer;
        color: red;
      }
    }
  }
}
</style>